import React from 'react';

import { Dropdown, Flex } from 'antd';

import { EllipsisOutlined } from '@ant-design/icons';

export default function ActionDropdown({ items }) {

    // items = [{
    //     key: "",
    //     label: "",
    //     icon: "",
    //     onClick: () => { }
    // }],

    return (<div onClick={(e) => e.stopPropagation()}>
        <Dropdown
            trigger={['click']}
            menu={{
                items
            }}
            placement="bottom"
        >
            <EllipsisOutlined style={{ cursor: "pointer", fontSize: 18 }} onClick={(e) => e.stopPropagation()} />
        </Dropdown>
    </div>
    )
}
