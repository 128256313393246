import axios from "axios";

const request = (method, url, body, callback, errorCallback = () => { }) => {

    let myAxios = axios.create();

    let TOKEN = localStorage.getItem("TOKEN")

    myAxios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;

    myAxios[method](url, method === "delete" ? { data: body } : body)
        .then((response) => {
            if (response?.data) {
                callback(response.data);
            }
        })
        .catch((error) => {
            let message = error?.response?.data

            if (message == "Forbidden") {
                localStorage.clear();
                window.location.href = "/";
                return;
            }

            if (message == "Your account is blocked" && !url.includes("login")) {
                localStorage.clear();
                window.location.href = "/";
                return;
            }
            errorCallback(error?.response?.data);
        });
};

export default request;
