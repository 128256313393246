import { useEffect, useState } from 'react';

import { Button, Checkbox, Col, DatePicker, Drawer, Flex, Form, Input, Row, Select } from 'antd';

import dayjs from 'dayjs';

import { REQUESTS } from '../../../api/requests';

import ValidateMacAddress from '../../../components/ValidateMacAddress';

import Message from '../../../components/Message';

import TextEditor from './TextEditor';

import UploadImg from './UploadImage';

import ourApps from "../../../assets/ourApps.json"
import styles from "../index.module.scss";

export default function AppsNotificationDrawer({ open, onClose, getData, actionType, current }) {
    const [form] = Form.useForm();

    const [select, setSelect] = useState({
        filter_type: null,
        filter_value: null,
    });

    const [mac, setMac] = useState("");

    const [checkedDate, setCheckedDate] = useState([]);

    const [appsList, setAppsList] = useState(ourApps.map(app => ({ ...app, checked: true })));

    const [message, setMessage] = useState({
        type: false,
        text: ""
    });

    const [editorValue, setEditorValue] = useState("");

    const [checkedAll, setCheckedAll] = useState(true);

    const [image, setImage] = useState([]);

    const onFinish = (values) => {
        setMessage({
            type: false,
            text: ""
        });

        const { title, expired_date, sended_date, name } = values;

        const formData = new FormData();

        formData.append('title', title);
        formData.append('desc', editorValue);
        formData.append('filter_type', select.filter_type);
        formData.append('type', "device");
        formData.append('name', name);

        const appsArray = appsList
            .filter(app => app.checked)
            .map(app => app.name);

        appsArray.forEach((item, index) => {
            formData.append(`apps[${index}]`, item);
        });

        if (image) {
            if (typeof image === 'string' && image.length) {
                formData.append('image_url', image);
            } else {
                formData.append('image', image);
            }
        }

        if (select.filter_type === "mac") {
            formData.append('filter_value', mac);
        } else {
            formData.append('filter_value', select.filter_value);
        }

        if (expired_date) {
            formData.append('expired_date', dayjs(expired_date).format('YYYY-MM-DD'));
        }

        if (sended_date) {
            formData.append('sended_date', dayjs(sended_date).format('YYYY-MM-DD'));
        }

        const callback = (data) => {
            getData();
            onClose();
            form.resetFields();
        };

        const errorCallback = (error) => {
            setMessage({
                type: false,
                text: error
            });
        };

        if (actionType === "edit") {
            formData.append('id', current.id);
            REQUESTS.NOTIFICATIONS.EDIT(formData, callback, errorCallback)
        } else {
            REQUESTS.NOTIFICATIONS.CREATE(formData, callback, errorCallback)
        }
    };

    const handleCheckAll = () => {
        const newCheckedAll = !checkedAll;
        setCheckedAll(newCheckedAll);
        setAppsList(appsList.map(app => ({ ...app, checked: newCheckedAll })));
    };

    useEffect(() => {
        if (!open) {
            setSelect({
                filter_type: null,
                filter_value: null,
            });

            setMac("");

            setCheckedDate([]);

            setAppsList(ourApps.map(app => ({ ...app, checked: true })));

            setMessage({
                type: false,
                text: ""
            });

            setEditorValue("");

            setImage(null);
        }
    }, [open])

    useEffect(() => {
        if (current && open && actionType == 'edit' || actionType == "repeat") {
            form.setFieldsValue({
                title: current.title,
                desc: current.desc,
                expired_date: current.expired_date ? dayjs(current.expired_date) : null,
                sended_date: current.sended_date ? dayjs(current.sended_date) : null,
                name: current.name,
            });

            // if (typeof current.image === 'string' && current.image.length) {
            //     const imageName = current.image.split('/').pop().split('?')[0];

            //     createFileFromURL(current.image, imageName)
            //         .then((file) => {
            //             setImage(file)
            //         })
            //         .catch(error => {
            //             console.error('Error converting URL to file:', error);
            //         });
            // } else {
            setImage(current.image);
            // }

            setEditorValue(current.desc);

            setSelect({
                filter_type: current.filter_type,
                filter_value: current.filter_value,
            });

            if (current.filter_type === "mac") setMac(current.filter_value);

            setCheckedDate(current.sended_date && current.expired_date ? ["sended_date", "expired_date"] : current.sended_date ? ["sended_date"] : current.expired_date ? ["expired_date"] : []);

            setAppsList(ourApps.map(app => {
                if (current?.apps.includes(app.name)) {
                    return { ...app, checked: true }
                }
                return { ...app, checked: false }
            }))
        }
    }, [open, actionType, current])

    useEffect(() => {
        setCheckedAll(appsList.every(app => app.checked));
    }, [appsList])

    return (
        <Drawer
            open={open}
            onClose={() => {
                onClose();
                form.resetFields();
            }}
            title={actionType == "edit" ? 'Update Notification' :
                actionType == "repeat" ? 'Repeat Notification' : 'Create Notification'}
            destroyOnClose={true}
            width={700}
        >
            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
            >
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Please input title!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Title For Admin"
                    name="name"
                    rules={[{ required: true, message: 'Please input name!' }]}
                >
                    <Input />
                </Form.Item>

                <label className={styles['label-val']}>Text</label>
                <TextEditor value={editorValue} setValue={setEditorValue} />

                <UploadImg setImage={setImage} image={image} />

                <>
                    <label className={styles['label-val']}>Devices By</label>
                    <Select
                        style={{ width: '100%', marginBottom: 16 }}
                        value={select.filter_type}
                        options={[
                            { label: 'All', value: 'all' },
                            { label: 'Platforms', value: 'platform' },
                            { label: 'Status', value: 'status' },
                            { label: 'Mac', value: 'mac' },
                        ]}
                        onChange={(val) => setSelect({
                            filter_type: val,
                            filter_value: val == "all" ? "all" : null,
                        })}
                    />
                </>
                <div style={{ marginBottom: 16 }}>
                    {(() => {
                        switch (select.filter_type) {
                            case "platform":
                                return <Select
                                    style={{ width: '100%' }}
                                    value={select.filter_value}
                                    options={[
                                        { label: 'Tizen', value: 'tizen' },
                                        { label: 'WebOS', value: 'webos' },
                                        { label: 'Android', value: 'android' },
                                        { label: 'Vidaa', value: 'vidaa' },
                                        { label: 'Foxxum', value: 'foxxum' },
                                        { label: 'Zeasn', value: 'zeasn' },
                                        { label: 'Vizio', value: 'vizio' },
                                        { label: 'TvOS', value: 'tvos' },
                                        { label: 'IOS', value: 'ios' },
                                        { label: 'Roku', value: 'roku' },
                                        { label: 'Android Mobile', value: 'android-mobile' },
                                    ]}
                                    onChange={(val) => setSelect({
                                        ...select,
                                        filter_value: val,
                                    })}
                                />
                            case "mac":
                                return <ValidateMacAddress value={mac} setValue={setMac} />
                            case "status":
                                return <Checkbox.Group
                                    value={[select.filter_value]}
                                    onChange={(val) => setSelect({
                                        ...select,
                                        filter_value: val[val.length - 1],
                                    })}
                                >
                                    <Row gutter={[16, 8]}>
                                        <Col span={16}>
                                            <Checkbox value="free_trial">Free Trial</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="free_trial_expired">Free Trial Expired</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="payed">Activated</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            default:
                                break;
                        }
                    })()}
                </div>

                <Checkbox.Group
                    value={checkedDate}
                    onChange={setCheckedDate}
                    style={{
                        width: '100%',
                        marginBottom: 16
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={16}>
                            <Checkbox value="sended_date">Send Date</Checkbox>
                        </Col>
                        <Col span={16}>
                            {checkedDate.includes("sended_date") &&
                                <Form.Item
                                    name="sended_date"
                                    style={{ marginBottom: 0 }}
                                >
                                    <DatePicker disabledDate={(current) => current && current < dayjs().endOf('day')} />
                                </Form.Item>}
                        </Col>
                        <Col span={16}>
                            <Checkbox value="expired_date">Expired Date</Checkbox>
                        </Col>
                        <Col span={16}>
                            {checkedDate.includes("expired_date") &&
                                <Form.Item
                                    name="expired_date"
                                    style={{ marginBottom: 0 }}
                                >
                                    <DatePicker disabledDate={(current) => current && current < dayjs().endOf('day')} />
                                </Form.Item>}
                        </Col>
                    </Row>
                </Checkbox.Group>

                <Col span={16}>
                    <Checkbox
                        checked={checkedAll}
                        onClick={handleCheckAll}
                    >
                        Check All
                    </Checkbox>
                </Col>
                <div className={styles['apps-list']}>
                    {
                        appsList
                            .map(app => <div key={app.id} >
                                <Checkbox
                                    value={app.name}
                                    style={{ width: '100%' }}
                                    checked={app.checked}
                                    onChange={(e) => {
                                        let checked = e.target.checked;
                                        const updatedAppsList = appsList.map(element => {
                                            if (element.id === app.id) {
                                                return { ...element, checked };
                                            }
                                            return element;
                                        });

                                        setAppsList(updatedAppsList);
                                        setCheckedAll(updatedAppsList.every(app => app.checked));
                                    }}
                                >
                                    <Flex align='center' gap={8}>
                                        {app.name}
                                    </Flex>
                                </Checkbox>
                            </div>)
                    }
                </div>
                <Message message={message} />
                <Flex justify='center' align='center' >
                    <Form.Item>
                        <Button htmlType='submit' type='primary' >Save</Button>
                    </Form.Item>
                </Flex>
            </Form>
        </Drawer >
    )
}
