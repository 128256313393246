import { useRef, useEffect, useState } from 'react';

import classNames from 'classnames';

import styles from "./styles/Message.module.scss";

export default function Message({
    message = {
        type: false,
        text: ""
    },
}) {
    const [maxHeight, setMaxHeight] = useState('0');

    const messageRef = useRef(null);

    useEffect(() => {
        if (message?.text) {
            const scrollHeight = messageRef?.current?.scrollHeight; // Get the height of the content
            setMaxHeight(`${scrollHeight}px`);
        } else {
            setMaxHeight('0');
        }
    }, [message]);

    return (
        <div
            ref={messageRef}
            className={classNames(styles["message"],
                { [styles["message-close"]]: !message.text },
                { [styles["message-open"]]: message.text })}
            style={{ maxHeight }}
        >
            <span
                className={classNames(
                    { [styles["error-text"]]: !message.type },
                    { [styles["success-text"]]: message.type })}
            >
                {message.text}
            </span>
        </div>
    )
}
