import React, { useEffect, useState } from 'react';

import { Modal, DatePicker, Table, Select, Flex, Alert } from 'antd';

import dayjs from 'dayjs';

import useResize from '../../../hooks/use-resize';

import { REQUESTS } from '../../../api/requests';

import { HOST } from '../../../config';

import apps from '../../../assets/ourApps.json';

import dateFormat from '../../../utils/dateFormat';

import TextEllipsisTooltip from '../../../components/styles/TextEllipsisTooltip';

export default function FreDnsDevicesByStatistics({ onClose, open, current }) {
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [total, setTotal] = useState(0);

    const [limit, setLimit] = useState(10);

    const [message, setMessage] = useState(null);

    const [year, setYear] = useState(null);

    const [month, setMonth] = useState(null);

    const [selected, setSelected] = useState(apps[0].id);

    const resize = useResize();

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                return (currentPage - 1) * limit + index + 1
            }
        },
        {
            title: 'Mac',
            dataIndex: 'device_mac',
            key: 'device_mac',
            align: 'center',
        },
        {
            title: 'Model',
            dataIndex: 'device.model',
            key: 'model',
            align: 'center',
        },
        {
            title: 'IP',
            dataIndex: 'device.ip',
            key: 'ip',
            align: 'center',
            render: (text) => <TextEllipsisTooltip text={text} />
        },
        {
            title: 'Created Date',
            dataIndex: 'device.createdAt',
            key: 'createdAt',
            align: 'center',
            render: (text) => dateFormat(text),
        },
    ];

    const getData = () => {
        if (!current) return;
        setLoading(true);
        setMessage(null);

        const query = {
            page: currentPage,
            limit,
            app_id: selected,
            multy_player_id: current?.id,
        }

        if (year && month) {
            query.year = year;
            query.month = month;
        }

        REQUESTS.FREE_DNS.FREE_DNS_DEVICES_BY_STATISTICS(query, (response) => {
            const data = response.message || {}
            setData(data.rows);
            setTotal(data.total);
            setLoading(false);
        }, (error) => {
            setMessage(error);
            setLoading(false);
        });
    }

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    }

    const onChange = (date, dateString) => {
        setYear(dateString.split('-')[0])
        setMonth(dateString.split('-')[1])
    };

    useEffect(() => {
        if (!open) return;

        let timeoutId = setTimeout(getData, 500);

        return () => clearTimeout(timeoutId);

    }, [currentPage, limit, year, month, open, current, selected]);

    useEffect(() => {
        if (open) {
            let month = new Date().getMonth() + 1;
            let year = new Date().getFullYear();
            setMonth(month < 10 ? `0${month}` : month);
            setYear(year);
        } else {
            setData([]);
            setLoading(false);
            setCurrentPage(1);
            setTotal(0);
            setLimit(10);
            setMessage(null);
            setSelected(apps[0].id);
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onCancel={onClose}
            title="Free DNS Devices By Statistics"
            width={resize < 600 ? "95%" : 600}
            destroyOnClose={true}
            closable={true}
            okButtonProps={{ style: { display: 'none' } }}
        >

            <DatePicker onChange={onChange} picker="month" defaultValue={dayjs(`${year}-${month}`, 'YYYY-MM')} allowClear={false} />

            <p style={{ marginTop: 30 }}>DNS: {current?.dns}</p>

            <Select
                style={{ width: "100%", margin: "24px 0" }}
                defaultValue={selected}
                onChange={setSelected}
            >
                {apps.map(app => (
                    <Select.Option key={app.id} value={app.id}>
                        <Flex align='center' gap={5}>
                            <img src={`${HOST}/${app.logo}`} width={23} /> {app.name}
                        </Flex>
                    </Select.Option>
                ))}
            </Select>

            {
                message ? <Alert
                    message={message}
                    type="warning"
                    showIcon
                /> :
                    <Table
                        dataSource={data}
                        columns={columns}
                        loading={loading}
                        onChange={handleTableChange}
                        pagination={{
                            current: currentPage,
                            total: total,
                            pageSize: limit,
                            showSizeChanger: true,
                        }}
                        scroll={{ x: "max-content" }}
                    />
            }
        </Modal>
    )
}
