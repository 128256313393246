import React from 'react'

import { Tooltip } from 'antd';

export default function TextEllipsisTooltip({
    width = 200,
    text
}) {
    return <Tooltip title={text}>
        <div style={{ width, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {text}
        </div>
    </Tooltip>
}
