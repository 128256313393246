import React, { useEffect, useState, memo } from 'react';

import { Button, Drawer, Form, Input, Checkbox, Select, Flex, Col, Row } from 'antd';

import { REQUESTS } from '../../../api/requests';

import useStore from '../../../hooks/use-store';
import Message from '../../../components/Message';

function DnsDrawer({ open, onClose, current, getFreeDNS, resellers = [] }) {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        text: "",
        type: ""
    });

    const [apps] = useStore("apps");

    const [checkedList, setCheckedList] = useState([]);

    const plainOptions = apps?.filter(item => item.free_dns_enabled).map((app) => app.id) || [];
    const indeterminate = checkedList?.length > 0 && checkedList?.length < plainOptions?.length;
    const checkAll = plainOptions?.length === checkedList?.length;

    const onChange = (list) => {
        setCheckedList(list);
    };

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? plainOptions : []);
    };

    const filterOption = (input, option) => {
        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    }

    const onFinish = (values) => {
        const { dns, remark, reseller_id } = values;

        setLoading(true);

        setMessage({
            type: false,
            text: ""
        })

        const body = {
            dns,
            reseller_id,
            app_ids: checkedList
        }

        if (remark) {
            body.remark = remark;
        }
        const callback = () => {
            setLoading(false);
            getFreeDNS();
            onClose();
        }

        const errorCallback = (error) => {
            setLoading(false);
            setMessage({
                type: false,
                text: error
            })
        }

        if (current) {
            body.id = current.id;
            REQUESTS.FREE_DNS.UPDATE(body, callback, errorCallback)
        } else {
            REQUESTS.FREE_DNS.ADD(body, callback, errorCallback)
        }
    };

    useEffect(() => {
        if (!open) {
            setMessage({
                type: false,
                text: ""
            });

            form.resetFields();
            setCheckedList([]);
        };
    }, [open]);

    useEffect(() => {
        if (current && open) {
            setCheckedList(current.apps?.map((app) => app.id) || []);

            form.setFieldsValue({
                reseller_id: current.reseller_id,
                dns: current.dns,
                remark: current.remark,
            })
        };
    }, [current, open]);

    return (
        <Drawer
            title={current ? "Edit DNS" : "Add DNS"}
            open={open}
            onClose={onClose}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="reseller_id"
                    label="Resseller"
                    rules={[
                        {
                            required: true,
                            message: 'Please select resseller!',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={resellers.map(reseller => ({
                            value: reseller?.value,
                            label: `${reseller?.label} (${reseller?.email})`
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label="DNS"
                    name="dns"
                    rules={[
                        {
                            required: true,
                            message: 'Please input DNS!',
                        },
                        {
                            type: 'url',
                            message: 'The input is not valid URL!',
                        },
                    ]}>
                    <Input disabled={current} />
                </Form.Item>
                <Form.Item
                    name="remark"
                    label="Remark"
                >
                    <Input.TextArea rows={4} style={{ resize: "none" }} />
                </Form.Item>
                <Col style={{ marginBottom: 18 }}>
                    <Row>
                        <Checkbox
                            indeterminate={indeterminate}
                            onChange={onCheckAllChange}
                            checked={checkAll}
                            style={{ marginBottom: 8 }}
                        >
                            Check All
                        </Checkbox>
                    </Row>

                    <Checkbox.Group value={checkedList} onChange={onChange} >
                        <Col>
                            {apps.filter(item => item.free_dns_enabled)?.map((app) => <Row
                                key={app.id}>
                                <Checkbox
                                    value={app.id}>
                                    {app.name}
                                </Checkbox>
                            </Row>)}
                        </Col>
                    </Checkbox.Group>
                </Col>
                <Message message={message} />
                <Flex justify="center">
                    <Form.Item>
                        <Button type='primary' loading={loading} htmlType='submit'>Save</Button>
                    </Form.Item>
                </Flex>
            </Form>
        </Drawer >
    )
}

export default memo(DnsDrawer);