import React, { useEffect, useState } from "react";

import { Button, Checkbox, Drawer, Form, Input, notification } from "antd";

import Message from "../../../components/Message";

import { REQUESTS } from "../../../api/requests";

export default function DnsMigrate({ open, onClose, currentDns, getData }) {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [message, setMessage] = useState({
        type: false,
        text: ""
    });

    const onFinish = (values) => {
        setLoading(true);

        setMessage({
            type: false,
            text: ""
        });

        const { dns, replace } = values;

        const body = {
            dns,
            "free_dns_id": currentDns.id,
            replace,
        }

        function callback(data) {
            onClose();
            getData();
            setLoading(false);
            notification.success({
                message: "DNS Migrate",
                description: "DNS Migrate successfully!",
            });
        }

        function errorCallback(error) {
            setLoading(false);

            setMessage({
                type: false,
                text: error.map(item => <p>{item?.name} {item?.message}</p>)
            });
        }

        REQUESTS.FREE_DNS.DNS_MIGRATE(body, callback, errorCallback)
    }

    useEffect(() => {
        if (!open) {
            setLoading(false);

            setMessage({
                type: false,
                text: ""
            });
            form.resetFields();
        }
    }, [open])

    return (
        <Drawer
            title="DNS Migrate"
            open={open}
            onClose={onClose}
        >
            <Form
                layout="vertical"
                form={form}
                initialValues={{ dns: "", replace: false }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="DNS"
                    name='dns'
                    rules={[
                        { required: true, message: "Please Input DNS!" },
                        {
                            validator: (rule, value, callback) => {
                                const urlPattern = /^(ftp|http|https):\/\/[^ "\/]+$/;
                                if (!value || urlPattern.test(value)) {
                                    callback();
                                } else {
                                    callback('Please enter a valid URL without a trailing slash!');
                                }
                            }
                        },]}
                >
                    <Input />
                </Form.Item>
                <Form.Item valuePropName="checked" name='replace' >
                    <Checkbox>Migrate playlists</Checkbox>
                </Form.Item>
                <Message message={message} />
                <Form.Item style={{ textAlign: "center" }} >
                    <Button disabled={loading} htmlType='submit'>Save</Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}
