import { useEffect, useState } from "react";

import { Upload, Image } from "antd";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const UploadImg = ({ setImage = () => { }, image }) => {
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const handleChange = (info) => {
        const newFileList = info.fileList.map(file => {
            setImage(file.originFileObj);
            return file;
        });
        setFileList(newFileList); // Update the fileList state
        setLoading(info.file.status === 'uploading');
    };

    const handleRemove = () => {
        setFileList([]);
        setImage(null); // Clear the image state when the image is removed
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    useEffect(() => {
        if (typeof image === 'string' && image.length) {
            const imageName = image.split('/').pop().split('?')[0];
            setFileList([{ uid: image, name: imageName, status: 'done', url: image }])
        }
    }, [image]);
    return <>
        <Upload
            listType="picture-card"
            accept="image/*"
            fileList={fileList}
            customRequest={({ file, onSuccess }) => {
                setTimeout(() => {
                    onSuccess("ok");
                    handleChange({ file, fileList: [{ uid: file.uid, name: file.name, status: 'done', originFileObj: file }] });
                }, 1000);
            }}
            onRemove={handleRemove}
            onPreview={handlePreview}
        >
            {fileList.length >= 1 ? null : uploadButton}
        </Upload>
        {previewImage && (
            <Image
                wrapperStyle={{
                    display: 'none',
                }}
                preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
            />
        )}
    </>
};

export default UploadImg;
