import { useState } from 'react';

import { Modal, Tabs, Flex, Button } from 'antd';

import StatisticsByDNS from './StatisticsByDNS';
import StatisticsByApps from './StatisticsByApps';

export default function DnsStatistics({ reseller, open, onCancel }) {
    const [activeTab, setactiveTab] = useState("1");

    return <Modal
        title="Free DNS Statistics"
        open={open}
        destroyOnClose={true}
        onCancel={onCancel}
        footer={null}
    >
        <Tabs
            defaultValue={activeTab}
            destroyInactiveTabTabPane={true}
            items={[
                {
                    key: '1',
                    label: 'Statistics By Apps',
                    children: <StatisticsByApps reseller={reseller} />,
                },
                {
                    key: '2',
                    label: 'Statistics By DNS',
                    children: <StatisticsByDNS reseller={reseller} />,
                },
            ]}
            onChange={(key) => setactiveTab(key)}
        />
        <Flex justify='flex-end'>
            <Button type="primary" onClick={onCancel}>Close</Button>
        </Flex>
    </Modal>
}

