import React, { useEffect, useState } from 'react'

import { Tag, DatePicker, Table, Alert } from 'antd';

import dayjs from 'dayjs';

import { REQUESTS } from '../../../api/requests';

export default function StatisticsByApps({ reseller }) {
    const [year, setYear] = useState(dayjs().year());

    const [month, setMonth] = useState(dayjs().month() + 1);

    const [data, setData] = useState([]);

    const [message, setMessage] = useState(null);

    const platforms = ["tizen", "webos", "android", "vidaa", "foxxum", "zeasn", "vizio", "tvos", "ios", "roku"];

    const availablePlatforms = platforms.filter(platform => {
        return data?.some(item => item?.hasOwnProperty(platform))
    });

    const columns = [
        {
            title: 'App',
            dataIndex: 'name',
            key: 'name',
        },
        ...availablePlatforms.map(platform => ({
            title: platform ? platform == "tizen" ? "Samsung" : platform == "webos" ? "LG" : platform[0].toUpperCase() + platform.substring(1) : '',
            dataIndex: platform,
            key: platform,
            render: (text, record) => {
                return <strong>{record[platform]}</strong>
            }
        })),
    ]

    const onChange = (date, dateString) => {
        setYear(dateString.split('-')[0])
        setMonth(dateString.split('-')[1])
    };

    const getStatistics = () => {
        setMessage(null);

        const query = {
            reseller_id: reseller.id,
            year,
            month,
        };

        function callback(data) {

            const dataModel = data.map((item, index) => {
                let obj = {
                    api_url: item.api_url,
                    id: item.id,
                    name: item.name,
                };

                if (item.statistics.error) {
                    return obj
                } else {
                    return {
                        ...obj,
                        ...item.statistics.message
                    }
                }
            })

            setData(dataModel);
        };

        function errorCallback(err) {
            setMessage(err);
        }

        REQUESTS.FREE_DNS.STATISTICS_APPS(query, callback, errorCallback)
    };

    useEffect(() => {

        if ( year && month && reseller) getStatistics();

    }, [year, month]);

    return (
        <div>
            <DatePicker
                onChange={onChange}
                picker="month"
                defaultValue={year && month ? dayjs(`${year}-${month}`, 'YYYY-MM') : null}
                style={{ marginBottom: 20 }}
            />
            {message ? <Alert
                message={message}
                type="warning"
                showIcon
            /> :
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    bordered
                    scroll={{ x: 'max-content' }}
                />}

            <strong style={{ marginTop: 20, display: "block" }}>
                Total: {
                    data.reduce((acc, item) => {
                        return acc + availablePlatforms.reduce((acc, platform) => {
                            return acc + (item[platform] || 0);
                        }, 0);
                    }, 0)
                }
            </strong>
        </div>
    )
}