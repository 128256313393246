import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import styles from "../index.module.scss"
export default function TextEditor({ value, setValue }) {

    return <div className={styles['editor-container']}>
        <ReactQuill
            theme="snow"
            value={value}
            onChange={setValue}
            modules={{
                toolbar: [
                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' },
                    { 'indent': '-1' }, { 'indent': '+1' }
                    ],
                    ['link',
                        // 'image', 'video'
                    ],
                    // ['clean']
                ],
                clipboard: {
                    matchVisual: false,
                }
            }}
            formats={[
                'header', 'font', 'size',
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet', 'indent',
                'link', 'image', 'video'
            ]}
        />
    </div>;
}
