import { Flex, notification } from 'antd';

import { REQUESTS } from '../../../api/requests';

import ICONS from '../../../config/icons';

import styles from "../index.module.scss";

export default function SyncStatus({ current, onClose }) {
    const syncStatus = (appId) => {
        const body = {
            app_id: appId,
            free_dns_id: current.id,
        };

        const callback = (message) => {
            onClose();
            notification.success({
                description: message,
            })
        };

        const errorCallback = (errMessage) => {
            onClose();
            notification.error({
                description: errMessage,
            })
        };

        REQUESTS.FREE_DNS.RETRY(body, callback, errorCallback)
    };

    return <div>
        {
            current?.apps?.map((app) => {
                if (app?.free_dns_apps.sync_status == "failed") {
                    return (<div className={styles['app-retry']} key={app.id}>
                        <Flex align="center" justify='space-between' gap={10}>
                            <div>
                                {app.name}
                                <p>{app.free_dns_apps.sync_error_message}</p>
                            </div>

                            <span onClick={() => syncStatus(app.id)}>
                                {ICONS.RETRY}
                            </span>
                        </Flex>
                    </div>)
                }
                return;
            })
        }
    </div>
}
